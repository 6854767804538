import { Application } from "@feathersjs/feathers";
import { LiveProvider, LiveEvent } from "@pankod/refine";

const liveProvider = (client: Application): LiveProvider => {
  return {
    subscribe: ({ channel, types, params, callback }) => {
      if (types.includes("*"))
        types = ["created", "updated", "patched", "removed"];
      channel = channel.replace("resources/", "");

      // console.log({channel, types, params, callback})
      types.forEach((type) => {
        client.service(channel).on(type, callback);
      });
      return { channel, types, callback };
    },

    unsubscribe: ({
      channel,
      types,
      callback,
    }: {
      channel: string;
      types: string[];
      callback: () => void;
    }) => {
      types.forEach((type) => {
        client.service(channel).removeListener(type, callback);
      });
    },

    publish: (event: LiveEvent) => {
      // console.log("publishing...", event);
    },
  };
};

export default liveProvider;
