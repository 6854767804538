import {
  Checkbox,
  Edit,
  Form,
  Input,
  IResourceComponentsProps,
  useForm,
} from "@pankod/refine";
import { useState } from "react";
import ReactMarkdown from "react-markdown";
import ReactMde from "react-mde";
import "react-mde/lib/styles/css/react-mde-all.css";
import { Comment } from ".";

export const CommentsEdit: React.FC<IResourceComponentsProps> = () => {
  const [selectedTab, setSelectedTab] = useState<"write" | "preview">("write");

  const { formProps, saveButtonProps, queryResult } = useForm<Comment>();

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Form {...formProps} initialValues={queryResult?.data} layout="vertical">
        <Form.Item label="Name" name="name" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item label="Slug" name="slug" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item label="Read" name="read" valuePropName="checked">
          <Checkbox />
        </Form.Item>
        <Form.Item label="Liked" name="liked" valuePropName="checked">
          <Checkbox />
        </Form.Item>
        <Form.Item label="Body" name="body" rules={[{ required: true }]}>
          <ReactMde
            selectedTab={selectedTab}
            onTabChange={setSelectedTab}
            generateMarkdownPreview={async (markdown) => (
              <ReactMarkdown>{markdown}</ReactMarkdown>
            )}
          />
        </Form.Item>
        <Form.Item label="Response" name="response">
          <ReactMde
            selectedTab={selectedTab}
            onTabChange={setSelectedTab}
            generateMarkdownPreview={async (markdown) => (
              <ReactMarkdown>{markdown}</ReactMarkdown>
            )}
          />
        </Form.Item>
      </Form>
    </Edit>
  );
};
