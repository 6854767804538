import feathersAuth from "@feathersjs/authentication-client";
import feathersClient from "@feathersjs/feathers";
import feathersSocket from "@feathersjs/socketio-client";
import { Refine } from "@pankod/refine";
import routerProvider from "@pankod/refine-react-router";
import "@pankod/refine/dist/styles.min.css";
import { authProvider } from "authProvider";
import dataProvider from "dataProvider";
import liveProvider from "liveProvider";
import { CommentsEdit, CommentsList } from "pages/comments";
import { SessionsList } from "pages/sessions";
import { SessionEventsList } from "pages/session-events";
import DashboardPage from "pages/dashboard";
import * as io from "socket.io-client";
import { Provider as FeathersProvider } from "figbird";
import Title from "components/Title";

const client = feathersClient();
const socket = io.connect("https://blog-api.aaronolsen.ca");
client.configure(feathersSocket(socket));
client.configure(
  feathersAuth({
    jwtStrategy: "jwt",
    storage: window.localStorage,
  })
);

function App() {
  return (
    <FeathersProvider feathers={client}>
      <Refine
        Title={Title}
        DashboardPage={DashboardPage}
        routerProvider={routerProvider}
        dataProvider={dataProvider(client)}
        authProvider={authProvider(client)}
        liveMode="auto"
        liveProvider={liveProvider(client)}
        resources={[
          {
            name: "comments",
            list: CommentsList,
            edit: CommentsEdit,
          },
          {
            name: "sessions",
            list: SessionsList,
          },
          {
            name: "session-events",
            list: SessionEventsList,
          },
        ]}
      />
    </FeathersProvider>
  );
}

export default App;
