import { Application } from "@feathersjs/feathers";
import { DataProvider } from "@pankod/refine";

const dataProvider = (client: Application): DataProvider => ({
  getList: ({ resource, pagination, filters, sort }) => {
    const current = pagination?.current || 1;
    const pageSize = pagination?.pageSize || 10;

    const $sort = sort?.reduce(
      (all, s) => ({ ...all, [s.field]: s.order === "desc" ? 1 : -1 }),
      {}
    );

    const $filters = filters?.reduce(
      (all, f) => ({
        ...all,
        [f.field]: { ["$" + f.operator]: f.value },
      }),
      {}
    );

    const query = {
      $skip: (current - 1) * pageSize,
      $limit: pageSize,
      $sort,
      ...$filters,
    };

    return client.service(resource).find({ query });
  },
  getMany: ({ resource, ids }) => {
    return client
      .service(resource)
      .find({ query: { id: { $in: ids }, $limit: ids.length } });
  },
  create: ({ resource, variables }) => {
    return client.service(resource).create(variables);
  },
  createMany: ({ resource, variables }) => {
    return Promise.all(variables.map(client.service(resource).create)) as any;
  },
  update: ({ resource, id, variables }) => {
    return client.service(resource).patch(id, variables);
  },
  updateMany: ({ resource, ids, variables }) => {
    return Promise.all(
      ids.map((id) => client.service(resource).patch(id, variables))
    ) as any;
  },
  getOne: ({ resource, id }) => {
    return client.service(resource).get(id);
  },
  deleteOne: ({ resource, id }) => {
    return client.service(resource).get(id);
  },
  deleteMany: ({ resource, ids }) => {
    return Promise.all(ids.map(client.service(resource).delete)) as any;
  },
  getApiUrl: () => {
    throw Error("Not implemented for Feathers data provider");
  },
});

export default dataProvider;
