import {
  DateField,
  getDefaultSortOrder,
  IResourceComponentsProps,
  List,
  NumberField,
  Table,
  TextField,
  useTable,
} from "@pankod/refine";

export interface SessionEvent {
  id: string;
  timeStamp: Date;
  event: string;
  label: string;
  length: number;
  sessionId: string;
  createdAt: number;
  updatedAt: number;
}

export const SessionEventsList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps, sorter } = useTable<SessionEvent>({
    initialSorter: [{ field: "createdAt", order: "asc" }],
  });

  return (
    <List>
      <Table {...tableProps} rowKey="id" size="small">
        <Table.Column
          dataIndex="timeStamp"
          title="Time"
          render={(value) => (
            <DateField format="YYYY-MM-DD h:mm A" value={value} />
          )}
          defaultSortOrder={getDefaultSortOrder("Time stamp", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="event"
          title="Event"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("event", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="label"
          title="Label"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("label", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="length"
          title="Length"
          render={(value) => <NumberField value={value} />}
          defaultSortOrder={getDefaultSortOrder("length", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="sessionId"
          title="Session"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("sessionId", sorter)}
          sorter
        />
      </Table>
    </List>
  );
};
