import { Application } from "@feathersjs/feathers";
import { AuthProvider } from "@pankod/refine";

const TOKEN_KEY = "username";

export const authProvider = (client: Application): AuthProvider => ({
  login: async ({ username, password }) => {
    try {
      const user = await client.authenticate({
        strategy: "local",
        email: username,
        password,
      });

      localStorage.setItem(TOKEN_KEY, user.email);
      return Promise.resolve();
    } catch {
      return Promise.reject(new Error("Invalid login"));
    }
  },
  logout: () => {
    localStorage.removeItem(TOKEN_KEY);
    client.logout();
    return Promise.resolve();
  },
  checkError: () => Promise.resolve(),
  checkAuth: () => {
    const hasJwtInStorage = !!localStorage.getItem(TOKEN_KEY);
    const hasReAuthenticate =
      Object.getOwnPropertyNames(client).includes("reAuthenticate") &&
      typeof client.reAuthenticate === "function";

    if (hasJwtInStorage && hasReAuthenticate) {
      return client
        .reAuthenticate()
        .then(() => Promise.resolve())
        .catch(() => Promise.reject());
    }

    return hasJwtInStorage ? Promise.resolve() : Promise.reject();
  },
  getPermissions: () => Promise.resolve(),
  getUserIdentity: () => {
    return client.reAuthenticate()
  },
});
