import {
  DateField,
  FilterDropdown,
  FilterDropdownProps,
  getDefaultSortOrder,
  Input,
  IResourceComponentsProps,
  List,
  NumberField,
  Table,
  TextField,
  useNavigation,
  useTable,
} from "@pankod/refine";

export interface Session {
  id: string;
  startedAt: Date;
  referrer: string;
  userAgent: string;
  language: string;
  length: number;
  latency: number;
  pageLoad: number;
  createdAt: Date;
  updatedAt: Date;
}

export const SessionsList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps, sorter } = useTable<Session>({
    initialSorter: [{ field: "createdAt", order: "asc" }],
  });
  const { edit } = useNavigation();

  return (
    <List>
      <Table
        {...tableProps}
        rowKey="id"
        size="small"
        onRow={(record) => ({ onClick: () => edit("comments", record.id) })}
      >
        <Table.Column
          dataIndex="id"
          title="Id"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("id", sorter)}
          sorter
          filterDropdown={(props: FilterDropdownProps) => (
            <FilterDropdown {...props}>
              <Input />
            </FilterDropdown>
          )}
        />
        <Table.Column
          dataIndex="startedAt"
          title="Started At"
          render={(value) => (
            <DateField format="YYYY-MM-DD h:mm A" value={value} />
          )}
          defaultSortOrder={getDefaultSortOrder("startedAt", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="userAgent"
          title="User Agent"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("userAgent", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="language"
          title="Language"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("language", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="length"
          title="Length"
          render={(value) => <NumberField value={value} />}
          defaultSortOrder={getDefaultSortOrder("length", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="latency"
          title="Latency"
          render={(value) => <NumberField value={value} />}
          defaultSortOrder={getDefaultSortOrder("latency", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="pageLoad"
          title="Page Load"
          render={(value) => <NumberField value={value} />}
          defaultSortOrder={getDefaultSortOrder("pageLoad", sorter)}
          sorter
        />
      </Table>
    </List>
  );
};
