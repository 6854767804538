const Title = ({ collapsed }: { collapsed: boolean }) => {
  return (
    <div
      style={{
        height: "72px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <span
        style={{
          fontSize: "1.6em",
          fontWeight: "bold",
          color: "#EEEEEE",
          overflow: "hide"
        }}
      >
      {collapsed ? "AO" : "Aaron Olsen"}
      </span>
    </div>
  );
};

export default Title;
