import {
  BooleanField,
  DateField,
  DeleteButton,
  FilterDropdown,
  FilterDropdownProps,
  getDefaultSortOrder,
  Input,
  IResourceComponentsProps,
  List,
  MarkdownField,
  Space,
  Table,
  TextField,
  UrlField,
  useNavigation,
  useTable,
} from "@pankod/refine";

export interface Comment {
  id: string;
  name: string;
  website: string;
  body: string;
  response: string | null;
  read: boolean;
  liked: boolean;
  slug: string;
  createdAt: number;
  updatedAt: number;
}

export const CommentsList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps, sorter } = useTable<Comment>({
    initialSorter: [{ field: "createdAt", order: "asc" }],
  });
  const { edit } = useNavigation();

  return (
    <List>
      <Table
        {...tableProps}
        rowKey="id"
        size="small"
        onRow={(record) => ({ onClick: () => edit("comments", record.id) })}
      >
        <Table.Column
          dataIndex="name"
          title="Name"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("name", sorter)}
          sorter
          filterDropdown={(props: FilterDropdownProps) => (
            <FilterDropdown {...props}>
              <Input />
            </FilterDropdown>
          )}
        />
        <Table.Column
          dataIndex="body"
          title="Body"
          render={(value) => <MarkdownField value={value} />}
          defaultSortOrder={getDefaultSortOrder("body", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="response"
          title="Response"
          render={(value) => <MarkdownField value={value} />}
          defaultSortOrder={getDefaultSortOrder("response", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="read"
          title="Read"
          render={(value) => <BooleanField value={value} />}
          defaultSortOrder={getDefaultSortOrder("read", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="liked"
          title="Liked"
          render={(value) => <BooleanField value={value} />}
          defaultSortOrder={getDefaultSortOrder("liked", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="slug"
          title="Slug"
          render={(value) => (
            <UrlField value={"https://aaronolsen.ca" + value}>{value}</UrlField>
          )}
          defaultSortOrder={getDefaultSortOrder("slug", sorter)}
          sorter
          filterDropdown={(props: FilterDropdownProps) => (
            <FilterDropdown {...props}>
              <Input />
            </FilterDropdown>
          )}
        />
        <Table.Column
          dataIndex="createdAt"
          title="Created"
          render={(value) => (
            <DateField format="YYYY-MM-DD h:mm A" value={value} />
          )}
          defaultSortOrder={getDefaultSortOrder("createdAt", sorter)}
          sorter
        />
        <Table.Column<Comment>
          title="Actions"
          render={(_, record) => (
            <Space>
              <DeleteButton hideText size="small" recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
