import { Card, Col, Row } from "@pankod/refine";
import DailySessions from "./DailySessions";
import LanguagesChart from "./LanguagesChart";

const DashboardPage: React.FC = () => {
  return (
    <Row gutter={[16, 16]}>
      <Col md={24}>
        <Row gutter={[16, 16]}>
          <Col xl={10} lg={24} md={24} sm={24} xs={24}>
            <Card
              bodyStyle={{
                padding: 10,
                paddingBottom: 0,
              }}
              style={{
                background: "url(images/daily-revenue.png)",
                backgroundColor: "#ffffff",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "right",
              }}
            >
              <DailySessions />
            </Card>
          </Col>
          <Col xl={7} lg={12} md={24} sm={24} xs={24}>
            <Card
              bodyStyle={{
                padding: 10,
                paddingBottom: 0,
              }}
              style={{
                background: "url(images/daily-order.png)",
                backgroundColor: "#ffffff",
                backgroundRepeat: "no-repeat",
              }}
            >
              <LanguagesChart />
            </Card>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default DashboardPage;
