import { Column, ColumnConfig } from "@ant-design/charts";
import DateRangePicker from "components/DateRangePicker";
import dayjs, { Dayjs } from "dayjs";
import { useFind } from "figbird";
import { useMemo, useState } from "react";

const DailySessions = () => {
  const [[start, end], setDateRange] = useState<[Dayjs, Dayjs]>([
    dayjs().subtract(7, "days").startOf("day"),
    dayjs().endOf("day"),
  ]);

  const { data: sessions } = useFind("sessions", {
    query: { startedAt: { $gt: start.toDate(), $lt: end.toDate() } },
    allPages: true,
  });

  const sessionsByDay = useMemo(() => {
    const byDay = [];

    for (let day = start; day.isBefore(end); day = day.add(1, "day")) {
      const daySessions = sessions?.filter((s) =>
        dayjs(s.startedAt).isSame(day, "day")
      );
      byDay.push({
        day: day.format("YYYY-MM-DD"),
        sessions: daySessions?.length ?? 0,
      });
    }

    return byDay;
  }, [sessions, start, end]);

  const config = useMemo(() => {
    const config: ColumnConfig = {
      data: sessionsByDay,
      xField: "day",
      yField: "sessions",
    };

    return config;
  }, [sessionsByDay]);

  return (
    <div>
      <DateRangePicker
        value={[start, end]}
        onChange={setDateRange}
        disabledDate={(date) => date > dayjs()}
      />
      <Column {...config} />
    </div>
  );
};

export default DailySessions;
