import { Pie, PieConfig } from "@ant-design/charts";
import { useFind } from "figbird";
import { useMemo } from "react";

const LanguagesChart = () => {
  const { data: sessions } = useFind("sessions", { allPages: true });

  const languages = sessions?.reduce(
    (acc, curr) => ({
      ...acc,
      [curr.language]: acc[curr.language] ? acc[curr.language] + 1 : 1,
    }),
    {}
  );

  const config: PieConfig = useMemo(
    () => ({
      data: languages
        ? Object.entries(languages).map(([lang, occ]) => ({ lang, occ }))
        : [],
      colorField: "lang",
      angleField: "occ",
      legend: { position: "bottom" },
    }),
    [languages]
  );

  return <Pie {...config} />;
};

export default LanguagesChart;
