import { DatePicker } from "@pankod/refine";
import dayjs, { Dayjs } from "dayjs";

type Props = {
  value: [Dayjs, Dayjs];
  onChange: (newDate: [Dayjs, Dayjs]) => void;
  disabledDate: (date: Dayjs) => boolean;
};

const DateRangePicker: React.FC<Props> = ({
  value,
  onChange,
  disabledDate,
}) => {
  return (
    <DatePicker.RangePicker
      className="range-picker"
      size="small"
      value={value}
      onChange={(values) => {
        if (values && values[0] && values[1]) {
          onChange([values[0], values[1]]);
        }
      }}
      disabledDate={disabledDate}
      style={{
        // float: "right",
        color: "#ffffff !important",
        background: "rgba(255, 255, 255, 0.3)",
      }}
      ranges={{
        Today: [dayjs().startOf("day"), dayjs().endOf("day")],
        "This Week": [dayjs().startOf("week"), dayjs().endOf("week")],
        "Last Month": [
          dayjs().startOf("month").subtract(1, "month"),
          dayjs().endOf("month").subtract(1, "month"),
        ],
        "This Month": [dayjs().startOf("month"), dayjs().endOf("month")],
        "This Year": [dayjs().startOf("year"), dayjs().endOf("year")],
      }}
      format="YYYY-MM-DD"
    />
  );
};

export default DateRangePicker;
